import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { range } from 'lodash-es'
import {
  Constants,
  EJobType,
  EJobTypeList,
  EOtherIncomeType,
  EOtherIncomeTypeList,
  translateErrorCode,
} from '@src/types'
import { yupResolver } from '@hookform/resolvers/yup'
import SelectInput from '@src/components/SelectInput'
import { InputText, ScrollableAlert } from '@src/components'
import { EmploymentInput, EmploymentInputSchema } from '../../../types/CreditApplicationSchema'
import { helpTip } from '../../../services/utils'
import { formatPhonesNumber, phoneFormat, removePhoneFormat } from '../../../services/Formatter'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  applicantData: EmploymentInput
  onApplicantUpdated: (data: EmploymentInput) => void
  nextStepAfter: (currentStep: string) => void
  currentStep: string
}

const EmploymentForm = ({ onApplicantUpdated, applicantData, nextStepAfter, currentStep }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const [displayMinIncomeMessage, setDisplayMinIncomeMessage] = React.useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm<EmploymentInput>({
    mode: 'onBlur',
    resolver: yupResolver(EmploymentInputSchema),
    defaultValues: applicantData,
  })
  const watchOtherIncomeTypes = watch('otherIncomes.0.typeId')
  const jobType = watch('currentJobs.0.jobType')
  const currentJobSalary = watch('currentJobs.0.annualSalary')
  const otherIncomeAnnualSalary = watch('otherIncomes.0.annualAmount')

  const onSubmit = (payload: EmploymentInput) => {
    if (Number(currentJobSalary ?? 0) + Number(otherIncomeAnnualSalary ?? 0) >= Constants.MinIncomes) {
      onApplicantUpdated(payload)
      nextStepAfter(currentStep)
    } else {
      setDisplayMinIncomeMessage(true)
    }
  }

  React.useEffect(() => {
    formatPhonesNumber()
    helpTip()
  }, [applicantData])

  const handlPhoneOnchange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    const phone = removePhoneFormat(value)
    setValue(id as 'currentJobs.0.employerPhone', phone)
    await trigger(id as 'currentJobs.0.employerPhone')
  }

  const handlPhoneExtOnchange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    const ext = removePhoneFormat(value)
    setValue(id as 'currentJobs.0.employerPhoneExt', ext)
    await trigger(id as 'currentJobs.0.employerPhoneExt')
  }

  React.useEffect(() => {
    setValue('otherIncomes.0.jobType', jobType)
  }, [jobType, setValue])

  React.useEffect(() => {
    if (!watchOtherIncomeTypes) {
      setValue('otherIncomes.0.annualAmount', null)
    }
  }, [setValue, watchOtherIncomeTypes])

  React.useEffect(() => {
    if ((Number(jobType) as EJobType) === EJobType.Unemployed) {
      setValue('currentJobs.0.annualSalary', null, { shouldValidate: true })
    }
  }, [jobType, setValue])

  return (
    <section className="step-content">
      <h3 className="form-question">{t('income.howMuch')}</h3>

      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <ScrollableAlert showAlert={displayMinIncomeMessage} type="warn" message={t('income.minAnnualSalary')} />
        <SelectInput
          id="employment_category"
          label="income.category"
          error={errors.currentJobs?.[0]?.jobType}
          disableEmptyValue
          defaultValue=""
          hasEmptyValue
          optionsFromEnum={EJobTypeList}
          optionsRootLabel="enum.eJobType"
          {...register('currentJobs.0.jobType')}
        />

        {(Number(jobType) as EJobType) !== EJobType.Unemployed && (
          <>
            <div className={`control-group  ${errors.currentJobs && errors.currentJobs[0]?.annualSalary && 'error'}`}>
              <label htmlFor="gross_anual">
                {t('income.beforeDeductions')}
                <span className="help-tip">
                  <p>{t('income.grossAnnualInfo')}</p>
                </span>
              </label>
              <div className={`number-wrap ${resolvedLanguage === 'en' ? 'startwith' : 'endwith'}`}>
                <InputText
                  type="number"
                  inputMode="decimal"
                  title={t('income.grossAnnualInfo')}
                  id="annualSalary"
                  error={errors.currentJobs?.[0]?.annualSalary}
                  {...register('currentJobs.0.annualSalary')}
                />
              </div>
            </div>
            <div className={`control-group ${errors.currentJobs && errors.currentJobs[0]?.jobTitle && 'error'}`}>
              <label htmlFor="job_title">{t('income.jobTitle')}</label>
              <input type="text" id="jobTitle" {...register('currentJobs.0.jobTitle')} maxLength={50} />
            </div>

            <div
              className={`control-group mid ${errors.currentJobs && errors.currentJobs[0]?.employerName && 'error'}`}
            >
              <label htmlFor="employer_name">{t('income.employerName')}</label>
              <input type="text" id="employerName" {...register('currentJobs.0.employerName')} maxLength={100} />
            </div>

            <div
              className={`control-group sml ${errors.currentJobs && errors.currentJobs[0]?.employerPhone && 'error'}`}
            >
              <label htmlFor="currentJobs.0.employerPhone">{t('income.phoneNumber')}</label>
              <div className="phone-input-wrapper">
                <input
                  id="currentJobs.0.employerPhone"
                  type="text"
                  inputMode="numeric"
                  placeholder="( ___ ) ___-____"
                  className="phone-input"
                  defaultValue={phoneFormat(getValues('currentJobs.0.employerPhone') as string)}
                  onBlur={handlPhoneOnchange}
                />
                <span className="phone-input-decorator">+1</span>
              </div>
            </div>
            <div
              className={`control-group sml ${
                errors.currentJobs && errors.currentJobs[0]?.employerPhoneExt && 'error'
              }`}
            >
              <label htmlFor="currentJobs.0.employerPhoneExt">{t('income.extension')}</label>
              <input
                type="text"
                inputMode="tel"
                placeholder="( ____ )"
                id="currentJobs.0.employerPhoneExt"
                defaultValue={getValues('currentJobs.0.employerPhoneExt') as string}
                onBlur={handlPhoneExtOnchange}
                maxLength={10}
              />
            </div>
            <div className="control-group">
              <label htmlFor="employment_years">{t('income.employmentLenght')}</label>
              <div className={`dropdown-wrap ${errors.currentJobs && errors.currentJobs[0]?.months && 'error'}`}>
                <select id="months" {...register('currentJobs.0.months')}>
                  <option value={0}>{t('common.years')}</option>
                  <option value={11}>{t('common.lessThan1year')}</option>
                  {range(1, 12).map((year) => (
                    <option value={year * 12} key={year}>
                      {t('common.yearsLabel', { count: year })}
                    </option>
                  ))}
                  <option value={12}>
                    {t('common.moreThan')} {t('common.yearsLabel', { count: 12 })}
                  </option>
                </select>
              </div>
            </div>
          </>
        )}
        <div className="control-group">
          <label htmlFor="gross_anual">
            <h1 className="h3">
              {t('income.otherIncome')}
              <span className="help-tip">
                <p>{t('income.otherIncomeInfo')}</p>
              </span>
            </h1>
          </label>
        </div>
        <div className="control-group mid">
          <label htmlFor="otherIncomeType">{t('income.otherIncome')}</label>
          <div className={`dropdown-wrap ${errors.otherIncomes && errors.otherIncomes[0]?.typeId && 'error'}`}>
            <select id="otherIncomeType" {...register('otherIncomes.0.typeId')}>
              <option aria-label={t('common.none')}> </option>
              {EOtherIncomeTypeList.map((item) => (
                <option key={item} value={item}>
                  {t(`enum.eOtherIncomeTypes.${item as EOtherIncomeType}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={`control-group mid ${errors.otherIncomes && errors.otherIncomes[0]?.annualAmount && 'error'}`}>
          <label htmlFor="other_source">{t('income.annualIncome')}</label>
          <div className={`number-wrap ${resolvedLanguage === 'en' ? 'startwith' : 'endwith'}`}>
            <input
              type="number"
              inputMode="decimal"
              id="annualAmount"
              disabled={!watchOtherIncomeTypes}
              {...register('otherIncomes.0.annualAmount')}
            />
            {errors.otherIncomes?.[0]?.annualAmount && (
              <p className="error-message" style={{ position: 'absolute' }}>
                {translateErrorCode(errors.otherIncomes?.[0]?.annualAmount, { context: 'annualAmount' })}
              </p>
            )}
          </div>
        </div>
        {(Number(watchOtherIncomeTypes) as EOtherIncomeType) === EOtherIncomeType.other && (
          <div className={`control-group ${errors.otherIncomes && errors.otherIncomes[0]?.description && 'error'}`}>
            <label htmlFor="other_source">{t('income.otherIncomeDescription')}</label>
            <input type="text" id="description" {...register('otherIncomes.0.description')} maxLength={50} />
          </div>
        )}
      </form>
    </section>
  )
}

export default EmploymentForm
